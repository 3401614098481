@import 'colors.scss';

footer#page-footer {
  width: 100%;
  display: flex;
  background-color: $secondary-color;
  color: #fff;
  margin: 0;
  padding: 1em;
  box-sizing: border-box;

  .footer-container {
    display: flex;
    margin: 80px auto;
    justify-content: space-between;
    max-width: 960px;
    width: 90%;

    @media (max-width: 920px) {
      margin-bottom: 50px;
      flex-direction: column-reverse;
    }
  }

  .logo-container {
    display: flex;
    flex-direction: column;
    font-size: 0.9em;

    @media (max-width: 920px) {
      margin-top: 2em;
      align-items: center;
    }

    .logo {
      height: 30px;
      margin: 10px 0;
      //width: 50px;
    }

    span {
      margin-bottom: 0.25em;
    }

    a {
      font-weight: 600;
      font-size: 0.8em;
      margin-bottom: 0.5em;
      color: #fff;
      margin-right: .8em;
    }
  }

  .footer-links {
    display: flex;
    @media (max-width: 920px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .footer-column {
      text-align: right;
      width: 160px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @media (min-width: 921px) {
        &.wider {
          width: 210px;
        }
        &.shorter {
          width: 115px;
        }
      }

      @media (max-width: 920px) {
        width: 45%;
        text-align: left;
        align-items: flex-start;
        margin-bottom: 1em;
      }

      h3 {
        color: #fff;
        text-transform: uppercase;
        font-size: 0.9em;
        font-weight: 400;
        margin-bottom: 1em;
        padding: 0 !important;

        @media (max-width: 920px) {
          display: block;
          width: 100%;
          font-weight: 600;
          border-bottom: 2px solid #fff;
          padding: 0 0 0.6em 0;
        }
      }

      a {
        font-weight: 600;
        margin-bottom: 0.75em;
        color: #fff;

        @media (max-width: 920px) {
          font-weight: 400;
        }
      }
    }
  }
}
